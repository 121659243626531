import { buildAppUrl } from '@/lib/utils';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'grommet';
import Link from 'next/link';
import styled from 'styled-components';

interface MenuItemProps {
  id: string;
  icon: IconDefinition;
  name: string;
}

const PlainLink = styled(Link)(({ theme }) => ({
  color: theme.global.colors.text,
  textDecoration: 'none',
  '&:visited': {
    color: 'unset',
  },
}));

const CustomBox = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  color: theme.global.colors.text,
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: theme.global.colors.secondary,
    color: theme.global.colors.secondaryText,
  },
}));

export function MenuItem(props: MenuItemProps) {
  return (
    <CustomBox key={props.id} direction="row" align="center" pad="xsmall">
      <FontAwesomeIcon
        icon={props.icon}
        style={{ marginRight: '8px' }}
        size="2xs"
      />

      <PlainLink
        href={buildAppUrl('shell', 'apps/{id}/', {
          id: props.id,
        })}
      >
        <Text weight="bold">{props.name}</Text>
      </PlainLink>
    </CustomBox>
  );
}
