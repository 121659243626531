import { FC } from 'react';
import { Label } from './Label';
import { DropSidebarItem } from './DropSidebarItem';
import { SidebarItemProps } from './types';
import { useAuthContext } from '@/lib/components/app/Auth/Auth';
import { PolicyProtected } from '@/lib/auth';

export const SidebarItem: FC<SidebarItemProps> = ({
  icon,
  label,
  href,
  requiresAuthentication = true,
  children,
  app,
  policy,
}) => {
  const { authenticated } = useAuthContext();

  if (requiresAuthentication && !authenticated) {
    return null;
  }

  let content = null;

  if (!children) {
    content = (
      <Label icon={icon} href={href} app={app}>
        {label}
      </Label>
    );
  } else {
    content = (
      <DropSidebarItem icon={icon} label={label}>
        {children}
      </DropSidebarItem>
    );
  }

  if (!policy) {
    return content;
  }

  return <PolicyProtected {...policy}>{content}</PolicyProtected>;
};
