'use client';

import { StyledTopBarItem } from '@/lib/components/app/Styled';
import { Profile } from '@/lib/components/icons';
import { useLogoutMutation } from '@/lib/generated/graphql';
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react';
import { ButtonExtendedProps, Menu } from 'grommet';
import { FC, useCallback, useMemo } from 'react';

export const ProfileButton: FC = () => {
  const { user, loginWithRedirect, logout: auth0Logout } = useAuth0();

  const [backendLogout] = useLogoutMutation();

  const logout = useCallback(
    async (params: LogoutOptions | undefined) => {
      try {
        const backendLogoutResult = await backendLogout();
        if (backendLogoutResult.data?.logout?.ok) {
          void auth0Logout(params);
        } else {
          console.error(`Couldn't log user out.`);
        }
      } catch (exception) {
        console.error(exception);
      }
    },
    [auth0Logout, backendLogout],
  );

  const items: ButtonExtendedProps[] = useMemo((): ButtonExtendedProps[] => {
    if (!user) {
      return [
        {
          label: 'Login',
          onClick: () => {
            void loginWithRedirect({
              openUrl: (url) => {
                const parsedUrl = new URL(url);
                parsedUrl.searchParams.append('app', 'uam');

                window.open(parsedUrl, '_self');
              },
              appState: {
                returnTo: '/my-fleet',
              },
            });
          },
        },
      ];
    }

    return [
      {
        label: user.email,
        style: {
          fontWeight: 'bold',
        },
        disabled: true,
      },
      {
        label: 'Sign out',
        onClick: () => {
          logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        },
      },
    ];
  }, [user, loginWithRedirect, logout]);

  return (
    <Menu
      items={items}
      dropProps={{
        align: {
          top: 'bottom',
          left: 'left',
        },
      }}
      plain
    >
      <StyledTopBarItem>
        <Profile />
      </StyledTopBarItem>
    </Menu>
  );
};
