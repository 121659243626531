import { Message } from '../types';
import { getSource } from './getSource';

export const replyToSource = (message: Message, response: Message) => {
  if (!message.message) {
    console.error('message cannot be replied, no message found');
    return;
  }

  const source = getSource(message.message);

  if (!source) {
    return;
  }

  source.postMessage(response, {
    targetOrigin: message?.origin || message.message.origin,
  });
};
