import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Spinner } from 'grommet';
import styled from 'styled-components';
import {
  replyToSource,
  useShellMessageListener,
} from '@/lib/contexts/PostMessageContext';
import {
  AuthMessageType,
  Message,
} from '@/lib/contexts/PostMessageContext/types';

const LoadingOverlay = styled(Box)<{ $loading: boolean }>(({ $loading }) => ({
  display: $loading ? 'flex' : 'none',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
}));

type AuthContextType = {
  authenticated: boolean;
};

const AuthContext = createContext<AuthContextType>({ authenticated: false });

export const useAuthContext = (): AuthContextType => {
  const ctx = useContext(AuthContext);
  if (!ctx) {
    throw new Error('No AuthContext found');
  }

  return ctx;
};

type AuthProps = {
  children: ReactNode;
};

export const Auth: FC<AuthProps> = ({ children }) => {
  const { isLoading, user, getAccessTokenSilently, loginWithRedirect } =
    useAuth0();

  const login = useCallback(() => {
    void loginWithRedirect({
      openUrl: (url) => {
        const parsedUrl = new URL(url);
        parsedUrl.searchParams.append('app', 'uam');

        window.open(parsedUrl, '_self');
      },
      appState: {
        returnTo: '/my-fleet',
      },
    });
  }, [loginWithRedirect]);

  const authContextState = useMemo((): AuthContextType => {
    return {
      authenticated: !!user,
    };
  }, [user]);

  const onGetSession = useCallback(
    async (m: Message) => {
      const message = m as Message<AuthMessageType>;

      if (message.payload.action !== 'getSession' || !user) {
        return;
      }

      try {
        const token = await getAccessTokenSilently();
        const sessionMessage: Message<AuthMessageType> = {
          id: m?.id,
          type: 'auth',
          payload: {
            user,
            token,
            action: 'setSession',
          },
        };

        replyToSource(message, sessionMessage);
      } catch (e) {
        return;
      }
    },
    [getAccessTokenSilently, user],
  );

  useShellMessageListener('auth', 'auth', onGetSession);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!user) {
      login();
      return;
    }

    getAccessTokenSilently().catch((e) => {
      console.log(e);
    });
  }, [isLoading, user, getAccessTokenSilently, login]);

  return (
    <AuthContext.Provider value={authContextState}>
      {children}
      <LoadingOverlay
        $loading={isLoading}
        justify="center"
        align="center"
        direction="column"
      >
        <Spinner />
      </LoadingOverlay>
    </AuthContext.Provider>
  );
};
