import { ThemeType } from 'grommet';
import { ButtonKindType, Colors } from 'grommet/themes/base';
import { css } from 'styled-components';

const colors: Colors = {
  brand: '#2B404E',
  secondary: '#F6C560',
  placeholder: 'rgba(72,72,72,0.5)',
  text: '#2B404E',
  secondaryText: '#484848',
  background: '#ededed',
  border: 'rgb(210, 210, 210)',
};

const defaultButton: ButtonKindType = {
  border: {
    color: 'rgba(43,64,78,0.5)',
    width: '1px',
    radius: '8px',
  },
  padding: {
    vertical: '6px',
    horizontal: '26px',
  },
  font: {
    weight: '600',
  },
  extend: css`
    text-transform: uppercase;
  `,
};

const primaryButton: ButtonKindType = {
  ...defaultButton,
  background: {
    color: 'brand',
  },
  color: 'white',
};

const secondaryButton: ButtonKindType = {
  ...defaultButton,
  background: {
    color: 'secondary',
  },
};

export const theme: ThemeType = {
  button: {
    default: defaultButton,
    primary: primaryButton,
    secondary: secondaryButton,
  },
  checkBox: {
    size: '15px',
    check: {
      radius: '0',
    },
    toggle: {
      radius: '0',
    },
  },
  global: {
    colors,
    focus: {
      border: {
        color: 'secondary',
      },
    },
    edgeSize: {
      large: '37px',
    },
    input: {
      padding: {
        vertical: '4px',
        horizontal: '12px',
      },
      font: {
        size: '16px',
        height: '24px',
      },
      extend: css`
        border-color: #dadada;

        ::placeholder {
          font-weight: 400;
        }
      `,
    },
    control: {
      border: {
        radius: '0',
      },
    },
  },
  text: {
    medium: {
      size: '15px',
    },
  },
  tag: {
    round: {
      size: '8px',
    },
    pad: {
      vertical: '2px',
    },
  },
  heading: {
    color: 'text',
    weight: 600,
  },
  tabs: {
    background: {
      color: '#ededed',
    },
    extend: css`
      // Target tab label
      [aria-selected='true'] > div {
        font-weight: 900;
        border-top: 4px solid ${(p) => p.theme.global.colors.brand};
      }

      [aria-selected='false'] > div {
        border-top: 4px solid white;
      }
    `,
  },
  tab: {
    background: {
      color: 'white',
    },
    border: {
      size: '0',
    },
    margin: '0',
    pad: {
      horizontal: '10px',
      vertical: '4px',
    },
    active: {
      background: {
        color: '#ededed',
      },
    },
  },
  table: {
    header: {
      background: 'secondary',
      border: undefined,
      pad: {
        vertical: '17px',
        horizontal: '13px',
      },
      extend: ({ className }) => {
        if (className === 'primary') {
          return css``;
        }

        return css`
          background-color: #d9d9d9;
        `;
      },
    },
    body: {
      background: 'white',
    },
  },
};
