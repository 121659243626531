import styled from 'styled-components';

const StyledItemDivider = styled.span`
  width: 100%;
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.global.colors.border};
`;

export function ItemDivider() {
  return <StyledItemDivider />;
}
