import { Compliance, Icon, Notification, Table } from 'grommet-icons';

type ExternalAppConfig = {
  moduleName: string;
  name: string;
  icon: Icon;
};

type ExternalApps = Record<string, ExternalAppConfig>;

const retoolApps: ExternalApps = {
  [process.env.NEXT_PUBLIC_RETOOL_APPS_TASKS as string]: {
    moduleName: 'sidebar-tasks',
    name: 'Tasks',
    icon: Compliance,
  },
  [process.env.NEXT_PUBLIC_RETOOL_APPS_ALERTS as string]: {
    moduleName: 'sidebar-alerts',
    name: 'Alerts',
    icon: Notification,
  },
  [process.env.NEXT_PUBLIC_RETOOL_APPS_FLEET_TABLE as string]: {
    moduleName: 'sidebar-fleet-table',
    name: 'Fleet Table',
    icon: Table,
  },
};

export const appsConfig = {
  shell: {
    baseUrl: process.env.NEXT_PUBLIC_SHELL_BASE_URL || 'http://localhost:8000',
  },
  uam: {
    baseUrl:
      process.env.NEXT_PUBLIC_UAM_BASE_URL || 'http://localhost:8000/uam',
  },
  retool: {
    environment: process.env.NEXT_PUBLIC_RETOOL_ENVIRONMENT,
    baseUrl: 'https://roadzdemo.retool.com/embedded/public',
    apps: retoolApps,
  },
  ai: {
    baseUrl:
      process.env.NEXT_PUBLIC_AI_APP_URL ||
      'https://mvir-ai-dev.teamroadz.com/',
  },
};

export type AppsConfiguration = typeof appsConfig;
