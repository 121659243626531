import { FC, ReactNode } from 'react';
import { useShellMessageListener } from '@/lib/contexts/PostMessageContext/hooks';
import { handshakeListener } from './coreListeners';

type CoreListenerProps = {
  children?: ReactNode;
};

export const CoreListener: FC<CoreListenerProps> = ({ children }) => {
  useShellMessageListener('__handshake', 'handshake', handshakeListener);

  return <>{children}</>;
};
