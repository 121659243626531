export const getSource = (e: MessageEvent): MessageEventSource | null => {
  try {
    // Special use-case where source seems to have 0 and 1 attributes
    // mainly on retool's context, you need to specifically use `0` in order for
    // messages to make it through
    if ((e.source as unknown as Array<MessageEventSource>)?.[0]) {
      return (e.source as unknown as Array<MessageEventSource>)[0];
    }
  } catch (e) {
    console.log(e);
  }

  return e.source;
};
