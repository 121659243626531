import { ExternalApp, useExternalAppsQuery } from '@/lib/generated/graphql';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { Box } from 'grommet';
import { FC } from 'react';
import { ItemDivider } from './ItemDivider';
import { MenuItem } from './MenuItem';

type ExternalAppEdge = {
  node: ExternalApp;
  __typename: 'ExternalAppEdge';
};

type ExternalAppsMenuProps = {
  category?: string;
};

export const ExternalAppsMenu: FC<ExternalAppsMenuProps> = ({ category }) => {
  const { data } = useExternalAppsQuery({
    variables: {
      first: 100,
      category,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <Box pad="none" overflow={{ vertical: 'auto' }} width={{ min: '200px' }}>
      <Box direction="column" align="start" pad="none">
        {(data?.externalApps?.edges.length || 0) > 0 && <ItemDivider />}
        {data?.externalApps?.edges
          ?.filter(
            (edge): edge is ExternalAppEdge =>
              edge?.__typename === 'ExternalAppEdge',
          )
          ?.map?.((edge) => (
            <>
              <MenuItem
                key={edge.node.id}
                id={edge.node.id}
                name={edge.node.name}
                icon={faArrowUpRightFromSquare}
              />
              <ItemDivider />
            </>
          ))}
      </Box>
    </Box>
  );
};
