import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';
import {
  Message,
  MessageListener,
  MessageListenerRegistry,
  MessageType,
  PostMessageContextType,
  ViewMode,
} from './types';
import { CoreListener } from './CoreListener';

const PostMessageContext = createContext<PostMessageContextType | null>(null);

export const publish = (payLoad: Message, targetOrigin: string) => {
  const childFrame = document.querySelector('iframe');
  childFrame?.contentWindow?.postMessage(payLoad, targetOrigin);
};

export const PostMessageContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const viewMode = useRef<ViewMode>('normal');
  const listeners = useRef<MessageListenerRegistry>({
    auth: {},
    navigation: {},
    viewMode: {},
    dataRefresh: {},
    streamlit: {},
    handshake: {},
  });

  const addListener = (
    id: string,
    type: MessageType,
    handler: MessageListener,
  ) => {
    const currentListeners = listeners.current;

    if (!currentListeners[type]) {
      currentListeners[type] = {};
      currentListeners[type][id] = handler;
    } else {
      currentListeners[type][id] = handler;
    }

    listeners.current = currentListeners;
  };

  const runListeners = useCallback((message: Message) => {
    const promises = Object.values(listeners.current[message.type]).map(
      (handler) => handler(message),
    );

    Promise.all(promises).catch((e) => {
      console.log(e);
    });
  }, []);

  const removeListener = (id: string, type: MessageType) => {
    const currentListeners = listeners.current;
    delete currentListeners[type][id];
    listeners.current = currentListeners;
  };

  const contextValue = {
    publish,
    viewMode,
    addListener,
    removeListener,
  };

  const messageHandler = useCallback(
    (message: MessageEvent) => {
      const receivedMessage: Message = {
        ...message.data,
        message,
      };

      const childFrame = document.querySelector('iframe');

      switch (receivedMessage.type) {
        case 'handshake':
          break;
        case 'auth':
          break;
        case 'navigation':
          console.log(
            'Navigating childFrame to',
            receivedMessage.payload.route,
          );
          if (childFrame) {
            childFrame.src = receivedMessage.payload.route;
          }
          break;
        case 'viewMode':
          viewMode.current = receivedMessage.payload.value;
          break;
        case 'dataRefresh':
          console.log('Data refresh message', message);
          break;
        case 'streamlit':
          break;
        default:
          return;
      }

      runListeners(receivedMessage);
    },
    [runListeners],
  );

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data satisfies Message) {
        messageHandler(event);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [viewMode, messageHandler]);

  return (
    <PostMessageContext.Provider value={contextValue}>
      <CoreListener>{children}</CoreListener>
    </PostMessageContext.Provider>
  );
};

export const usePostMessageContext = () => {
  const ctx = useContext(PostMessageContext);

  if (!ctx) {
    throw new Error('Could not find PostMessageContext');
  }

  return ctx;
};
