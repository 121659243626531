import { MessageListener } from '@/lib/contexts/PostMessageContext/types';
import { replyToSource } from '@/lib/contexts/PostMessageContext';

export const handshakeListener: MessageListener = async (message) => {
  console.groupCollapsed('[shell] handshakeListener received a message');
  console.log(message);
  console.groupEnd();

  try {
    replyToSource(message, {
      type: 'handshake',
      payload: {},
    });
  } catch (e) {
    console.error(e);
  }
};
