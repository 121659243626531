import { PolicyCheck, useCheckPolicyQuery } from '@/lib/generated/graphql';
import { FC, ReactNode } from 'react';

type PolicyProtected = PolicyCheck & {
  children?: ReactNode;
};

/**
 * Helper component that allows wrapping individual components to be protected
 * by a policy.
 *
 * The policy is evaluated against the backend, and based on the result, it either
 * renders - or not - the children.
 *
 * @param children
 * @param policy
 * @constructor
 */
export const PolicyProtected: FC<PolicyProtected> = ({
  children,
  ...policy
}) => {
  const { data, loading } = useCheckPolicyQuery({
    variables: {
      policy,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return null;
  }

  if (!data?.checkPolicy) {
    return null;
  }

  return <>{children}</>;
};
